/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';
import {MaxWidthMediumScreen} from '@constants';
import {DatadogService, SidebarService} from '@services/core';
import {AuthService} from '@auth0/auth0-angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    public isAuthGuardLoading = false;

    private openSubscriptions: Subscription[] = [];

    constructor(
        public readonly authService: AuthService,
        private readonly _datadogService: DatadogService,
        private readonly _router: Router,
        private readonly _sidebarService: SidebarService,
        private readonly _breakpointObserver: BreakpointObserver,
        _translateService: TranslateService) {
        _translateService.setDefaultLang('en');
        _translateService.use('en');
    }

    public ngOnInit(): void {
        this._datadogService.initiate();
        this.initiateSubscriptions();
    }

    private initiateSubscriptions(): void {
        const routerSub = this._router.events.subscribe(event => {
            if (event instanceof GuardsCheckStart) {
                this.isAuthGuardLoading = true;
                return;
            }
            if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                this.isAuthGuardLoading = false;
            }
        });

        const breakSub = this._breakpointObserver
            .observe([`(max-width: ${MaxWidthMediumScreen})`])
            .subscribe((state) => {
                this._sidebarService.isSmallScreen = state.matches;
                this._sidebarService.isSmallScreenSubject.next(state.matches);

                if (!this._sidebarService.isSmallScreen && this._sidebarService.isMenuOpen) {
                    this._sidebarService.toggleSidebar();
                }
            });
        this.openSubscriptions.push(breakSub, routerSub);
    }

    ngOnDestroy(): void {
        this.openSubscriptions.forEach((s) => s.unsubscribe());
    }
}
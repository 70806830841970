import {Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import environment from '@environment';
import {AuthService} from '@auth0/auth0-angular';
import {Store} from '@ngrx/store';
import {delay, filter, first} from 'rxjs';
import {datadogRum} from '@datadog/browser-rum';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {AuthenticationService, FeatureFlagService, SidebarService, UserAgentDetectorService} from '@services/core';
import {OnboardingService} from '@services/shared';
import {ILoadingState, isLoading} from '@store/loading';
import {SafeHtmlPipe} from '@pipes/safe-html.pipe';
import {LoadingItemsContainerComponent} from './loading-items-container/loading-items-container.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {downloadItems} from '@store/downloading';

export interface IDownloadCounter {
    completed: number;
    total: number;
    areRemainingItemsFailed: boolean;
}

@Component({
    standalone: true,
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
    imports: [CommonModule, TranslateModule, MatProgressBarModule, SafeHtmlPipe, MatMenuModule, RouterLink, LoadingItemsContainerComponent],
    providers: [OnboardingService],
    encapsulation: ViewEncapsulation.None,
})
export class NavBarComponent implements OnInit, OnDestroy {
    public shouldShowSidenavMenu = true;
    public profileLink = `${environment.defaultConfiguration.auth.accountManagementAppUrl}/main/account-management/account-details`;
    public isLoading = false;
    public onboardingProgress = 9;
    public shouldShowOnboardingComponent: boolean;
    public shouldShowDownloadItems = false;
    public downloadItemsCount: IDownloadCounter = {completed: 0, total: 0, areRemainingItemsFailed: false};
    public hideNavBar = false;

    public isCollapsed = true;

    constructor(
        public authService: AuthService,
        public onboardingService: OnboardingService,
        private readonly _store: Store<ILoadingState>,
        private readonly _authenticationService: AuthenticationService,
        private readonly _featureFlagService: FeatureFlagService,
        private readonly _destroyRef: DestroyRef,
        private readonly _sidebarService: SidebarService,
        private readonly _router: Router,
        private readonly _userAgentDetectionService: UserAgentDetectorService
    ) {
    }

    ngOnInit(): void {
        this.setDatadogUser();
        this.hideNavBar = this._userAgentDetectionService.isAIHRMobileApp();
    }

    public logout(): void {
        this._authenticationService.logout();
    }

    public onToggleMenu(): void {
        this.isCollapsed = !this.isCollapsed;
        this._sidebarService.toggleSidebar();
    }

    public toggleDownloadContainer(): void {
        this.shouldShowDownloadItems = !this.shouldShowDownloadItems;
    }

    private initiateSubscriptions(): void {
        this._router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.shouldShowSidenavMenu = !event.urlAfterRedirects.includes('error/');
            }
        });

        this._store.select(isLoading).pipe(delay(100), takeUntilDestroyed(this._destroyRef)).subscribe(res => {
            this.isLoading = res;
        });

        this._store.select(downloadItems).pipe(filter(items => !items.size), takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this.shouldShowDownloadItems = false;
        });

        this.onboardingService.shouldShowOnboardingComponent().pipe(first()).subscribe(showOnboarding => {
            this.shouldShowOnboardingComponent = showOnboarding;
        });

        this.onboardingService.getOnboardingProgress().pipe(first()).subscribe(progress => {
            this.onboardingProgress = progress;
        });
    }

    // TODO: Create a proper Store action to set the user when we know for sure that we have the decoded token available and move this logic there.
    private setDatadogUser(): void {
        this._authenticationService.user$.pipe(first()).subscribe(user => {
            const attemptToDecodeToken = () => {
                const decodedToken = this._authenticationService.getDecodedToken();

                if (!decodedToken) {
                    // If decodedToken is not available, retry after a short delay
                    setTimeout(attemptToDecodeToken, 100); // Adjust the delay as needed
                } else {
                    // Proceed with the logic once decodedToken is available
                    const authenticatedUser = {
                        ...user,
                        bnw_user_id: decodedToken.bnw_user_id,
                        'AIHR/Roles': decodedToken['AIHR/Roles']
                    };

                    datadogRum.setUser({
                        id: authenticatedUser?.bnw_user_id,
                        email: authenticatedUser?.email,
                        name: authenticatedUser?.name,
                        roles: authenticatedUser['AIHR/Roles']
                    });

                    this._featureFlagService.initialize(user);
                    this.initiateSubscriptions();

                    if (user?.email?.includes('@aihr.com')) {
                        datadogRum.stopSessionReplayRecording();
                    }
                }
            };

            attemptToDecodeToken();
        });
    }

    ngOnDestroy(): void {
        this._featureFlagService.destroy();
    }
}

/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UserAgentDetectorService {
    private readonly userAgent: string;

    constructor() {
        this.userAgent = navigator.userAgent || '';
    }

    /**
     * Checks if the user agent ends with the specified suffix.
     * @returns true if the user agent ends with "-AIHRMobileApp", false otherwise.
     */
    public isAIHRMobileApp(): boolean {
        return this.userAgent.endsWith('-AIHRMobileApp');
    }
}

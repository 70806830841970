@if(shouldShowItems){
    <div class="loading-items-container" id="loading-items-container">
        <div class="toggle-area">
            <div class="h3-standard" id="loading-items-title">
                {{ 'download.counter' | translate: { completed: downloadCounter.completed, total: downloadCounter.total } }}
            </div>
            @if(downloadCounter.completed === downloadCounter.total || downloadCounter.areRemainingItemsFailed){
                <button class="action-button n14-bold" (click)="clearDownloadQueue()">{{ 'download.closeButton' | translate }}</button>
            } @else {
                <button class="action-button n14-bold" (click)="cancelOngoingDownloads()">{{ 'download.cancelButton' | translate }}</button>
            }
        </div>
            <div>
                <hr>
                @for (item of items.values(); track item.id; let index = $index) {
                    <div 
                        class="loading-card" 
                        [ngClass]="{
                            'success': item.isSuccess === true, 
                            'failure': item.isSuccess === false,
                        }"
                        >
                        <div class="text-area">
                            @if(item.isSuccess === undefined){
                                <i class="fa-solid fa-circle-notch fa-spin"></i>
                            } @else { 
                                <i class="fa-light"
                                    [ngClass]="{
                                        'fa-circle-check': item.isSuccess,
                                        'fa-triangle-exclamation':
                                        item.isSuccess === false || item.isCanceled }"
                                ></i>
                            }
                            <div class="text-container">
                                <div class="n16-regular" id="download-item-{{ index }}-name">
                                    {{ item.title | translate }}
                                </div>
                                @if(item.isSuccess === false){
                                    <div class="error-text n12-regular" id="download-item-{{ index }}-error-message"> {{ (item.isCanceled ? 'download.canceledMessage' : 'download.failedMessage') | translate }}</div>
                                }
                            </div>
                        </div>
                        @if(item.isSuccess === undefined){
                            <button class="close-icon" (click)="cancelDownload(item)">
                                <i class="fal fa-xmark" aria-hidden="true"></i>
                            </button>
                        } @else if(item.isSuccess === false && !item.isCanceled) {
                            <button class="retry-button n12-cap-bold" (click)="retryDownload(item)">
                                {{ 'download.retryButton' | translate }}
                            </button>
                        }
                    </div>
                }
            </div>
    </div>
}

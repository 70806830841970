/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { NavigationStart, Router } from '@angular/router';
import { cancelDownloadingItem, clearCompletedDownloads, clearDownloadItems, downloadItems, IDownloadItem, retryDownloadingItem } from '@store/downloading';
import { IDownloadCounter } from '../nav-bar.component';

@Component({
    standalone: true,
    selector: 'app-loading-items-container',
    templateUrl: './loading-items-container.component.html',
    styleUrls: ['./loading-items-container.component.scss'],
    imports: [CommonModule, TranslateModule, MatProgressBarModule]
})
export class LoadingItemsContainerComponent implements OnInit {
    @Input({ required: true }) public shouldShowItems = false;
    @Input({ required: true }) public downloadCounter: IDownloadCounter;

    public items = new Map<string, IDownloadItem>();

    private get itemsArray(): IDownloadItem[] {
        return Array.from(this.items.values());
    }

    constructor(
        private readonly _store: Store,
        private readonly _destroyRef: DestroyRef,
        private readonly _router: Router) {
    }

    ngOnInit(): void {
        this._store.select(downloadItems).pipe(takeUntilDestroyed(this._destroyRef)).subscribe(items => {
            this.items = items;
            this.downloadCounter.total = this.items.size;
            this.downloadCounter.completed = this.getNumberOfCompletedItems();
            this.downloadCounter.areRemainingItemsFailed = this.checkIfRemainingItemsAreFailed();
        });

        this._router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(event => {
            if (event instanceof NavigationStart) {
                this._store.dispatch(clearCompletedDownloads());
            }
        });
    }

    public cancelDownload(item: IDownloadItem): void {
        this._store.dispatch(cancelDownloadingItem({ id: item.id }));
    }

    public retryDownload(item: IDownloadItem): void {
        this._store.dispatch(retryDownloadingItem({ id: item.id }));
    }

    public clearDownloadQueue(): void {
        this._store.dispatch(clearDownloadItems());
    }

    public cancelOngoingDownloads(): void {
        this.items.forEach(item => {
            if (item.isSuccess === undefined) {
                this.cancelDownload(item);
            }
        });
    }

    private checkIfRemainingItemsAreFailed(): boolean {
        const areItemsStillDownloading = this.itemsArray.some(item => item.isSuccess === undefined);
        return !areItemsStillDownloading && this.itemsArray.some(item => !item.isSuccess);
    }

    private getNumberOfCompletedItems(): number {
        return this.itemsArray.filter(item => item.isSuccess).length;
    }
}
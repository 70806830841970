/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { Injectable } from '@angular/core';
import environment from '@environment';
import { initialize, LDClient, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, distinctUntilChanged, Observable, of } from 'rxjs';
import { User } from '@auth0/auth0-angular';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private readonly isServiceInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isServiceInitialized$ = this.isServiceInitialized.asObservable();

    private client: LDClient | null = null;
    private flagSubjects: { [key: string]: BehaviorSubject<LDFlagValue> } = {};

    public initialize(user: User): void {
        if (!user) {
            return;
        }

        if (this.client) {
            this.destroy();
        }

        this.client = initialize(
            environment.defaultConfiguration?.auth.launchDarklyClientId,
            {
                kind: 'user',
                key: user.email,
                user
            }
        );

        this.client.on('ready', _ => this.isServiceInitialized.next(true));
        this.client.on('error', _ => this.isServiceInitialized.next(false));
    }

    public getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
        if (!this.client) {
            return of(defaultValue);
        }

        if (!this.flagSubjects[flagKey]) {
            this.flagSubjects[flagKey] = new BehaviorSubject<LDFlagValue>(defaultValue);
            this.client.on(`change:${flagKey}`, () => {
                const newValue = this.client.variation(flagKey, defaultValue);
                this.flagSubjects[flagKey].next(newValue);
            });

            // Initialize with current value
            this.client.waitUntilReady().then(() => {
                const initialValue = this.client.variation(flagKey, defaultValue);
                this.flagSubjects[flagKey].next(initialValue);
            });
        }

        return this.flagSubjects[flagKey].asObservable().pipe(distinctUntilChanged());
    }

    public goToExternalUrlAndFlush(targetUrl: string): void {
        if (!targetUrl) {
            return;
        }
        try{
            this.client.flush()
                .finally(() => {
                    window.location.href = targetUrl;
                });
        }
        catch (error) {
            window.location.href = targetUrl;
        }
    }

    public destroy(): void {
        if (this.client) {
            this.client.flush(() => {
                this.client?.close();
                this.client = null;
                this.isServiceInitialized.next(false);

                // Reset flagSubjects
                Object.values(this.flagSubjects).forEach(subject => subject.complete());
                this.flagSubjects = {};
            });
        }
    }
}
